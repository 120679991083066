<template>
    <div class="main-container">
      <h1 class="main-heading">
        <div> Faction Config </div>
        <Button
        text="Save"
        :loading="isLoading"
        @click="openConfirm"
        type="info"
      />
      </h1>
      <div class="content-container">
        <div class="content-row-container">
          <div class="content-row">
          <div class="content-container sub-container">
            <div class="content-row"
                v-for="subKey in configConstants.factionDataKeys"
                v-bind:key="subKey">
                <div class="row-title">
                  {{ $t(`DB_VALUES.faction_data.${subKey}`) }}
                </div>
                <select v-if="subKey === 'picked_faction'" v-model="factionObj.picked_faction" :disabled="env==='prod'">
                    <option v-for="option in factions" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                </select>
                <select v-if="subKey === 'picked_skill'" v-model="factionObj.picked_skill" :disabled="env==='prod'">
                    <option v-for="option in skills" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                </select>
                <DatePicker
                  v-if="['schedule', 'last_edit_time'].includes(subKey)"
                  v-model="factionObj[subKey]"
                  mode="dateTime"
                  :min-date="Date.now()"
                  :model-config="{
                    type: 'number',
                    mask: 'DD/MM/YYYY HH:mm',
                  }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input id="date4" :value="inputValue" v-on="inputEvents" :disabled="subKey === 'last_edit_time'"/>
                  </template>
                </DatePicker>
                <input v-if="subKey === 'enabled'" type="checkbox" v-model="factionObj.enabled">
              </div>
          </div>
          </div>
        <br>
        <div class="table">
          <h4>Faction History</h4>
          <div class="table-row">
            <h4 class="cell"> Faction </h4>
            <h4 class="cell"> Skill </h4>
            <h4 class="cell"> Change Date </h4>
          </div>
          <div class="table-row"
              v-for="(elem, index) in factionHistory"
              v-bind:key="index"
              :class="index == factionObj.faction_index - 1 ? 'picked': ''">
              <div class="cell">{{ elem.faction }}</div>
              <div class="cell">{{ elem.skill }}</div>
              <div class="cell">{{ moment(elem.date).format('DD/MM/YYYY HH:mm') }}</div>
            </div>
        </div>
      </div>
    </div>

      <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>
    </div>
  </template>
  
  <script>
import { onMounted, computed, ref, onBeforeMount, onBeforeUnmount, watch} from "vue"; 
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import configConstants from "@/constants/configConstants";
  
  
import 'v-calendar/dist/style.css';
import Button from "../../components/common/Button.vue";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue';
import moment from "moment";
  
import dispatchMap from "@/constants/dispatchMap";

export default {
name: "FactionsConfig",
components: {
    Button,
    ConfirmPopup
},
setup() {
    const store = useStore();
    const usedPages = ["factionsConfig"];
    const isLoading = ref(false);
    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
              store.dispatch(dispatchStr); 
          })
      });
      }
    );
    const route = useRoute();
    const router = useRouter();
    console.log(router)
    const popupOpen = ref(false);
    const popupText = ref("Are you sure with these changes?")
    const popupType = ref("refresh")
    const unitOptions = ref([]);
    const unitValue = ref([]);
    const pickedFaction = ref("")
    const pickedSkill = ref("")
    const factionObj = ref({});
    const factions = computed(() => store.getters['factionSystem/getFactions']);
    const factionConfigObj = computed(() => store.getters['serverConfig/getFactionsConfig']);
    const skills = computed(() => store.getters['skills/getSkills']);

    const factionHistory = ref([]);

    onMounted(() => {
      store.dispatch("loader/loadingStatus", false)
    });

    watch(() => store.getters["serverConfig/getFactionsConfig"], () => {
          factionObj.value = JSON.parse(JSON.stringify(factionConfigObj.value));
    }, {immediate: true});

    watch(() => store.getters["serverConfig/getFactionsConfig"], () => {
          for(let i=0; i<factionObj.value.faction_history.length;i++){
            const faction = factions.value.find(x => x.id === factionObj.value.faction_history[i].faction_id);
            const skill = skills.value.find(x => x.id === factionObj.value.faction_history[i].skill_id);
            factionHistory.value.push({faction: faction.name, skill: skill.name, date:factionObj.value.faction_history[i].date})
          }
    });

    console.log(factionHistory.value)

    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const submit = async () => {
      const setObj = {};
      Object.keys(factionObj.value).forEach((key) => {
          setObj[key] = factionObj.value[key];
      });
      if(factionConfigObj.value.picked_faction !=factionObj.value.picked_faction || factionConfigObj.value.picked_skill !=factionObj.value.picked_skill)
        setObj.last_edit_time = Date.now();
      store.dispatch("serverConfig/setFactionsConfig", setObj).then(() => {
      isLoading.value = false;
      popupOpen.value = false;
      });
    }

    const exportTo = environment => {
    store.dispatch('news/exportNews', { id: route.query.id, data: factionObj, environment })
    }

    const openConfirm = () => {
        popupType.value = "confirm";
        popupOpen.value = true;
    }

    const getChangeDate = (index) => {
        const lastDate = new Date(factionObj.value.last_edit_time)
        return lastDate.setDate(lastDate.getDate() + ((index - factionObj.value.faction_index + 1) * 7))
    }
    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
    factionObj,
    submit,
    exportTo,
    openConfirm,
    env,
    popupText,
    popupOpen,
    popupType,
    unitOptions,
    unitValue,
    configConstants,
    pickedFaction,
    pickedSkill,
    isLoading,
    factions,
    skills,
    factionHistory,
    getChangeDate,
    moment
    }
}
}
</script>
  
  <style scoped>
  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }

  .table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}

.table-row.picked {
  background-color: rgb(183, 184, 242);
}
  
  .content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .content-row {
    padding: 15px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
  }
  
  .content-row-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .row-title {
    width: 40%;
    text-align: start;
  }
  
  .content-container.sub-container {
    border: 1px solid black;
  }
  
  .main-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  </style>