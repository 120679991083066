export default {
    osSpecificValues: [
        "version",
        "review_version",
        "test_version",
        "maintenance_date"
    ],
    factionDataKeys: [
        "schedule",
        "frequency",
        "last_edit_time",
        "picked_faction",
        "picked_skill",
        "enabled"
    ],
    CONNECTION_PROTOCOLS: [
        {
            value: 0,
            text: "KCP (UDP)"
        },
        {
            value: 1,
            text: "TCP"
        },
        {
            value: 2,
            text: "Web Socket"
        },
        {
            value: -1,
            text: "A/B Testing Mode"
        },
    ],
}